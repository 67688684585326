import React from "react";
import Main from "../components/main";
import style from "./index.module.css";

function Home() {
  return (
    <Main>
      <h3>Ryan Rivera</h3>
      <h1 className={style.title}>Front-end web developer</h1>
      <p>
        Hello, I’m <em>Ryan Rivera</em>. I currently reside in Vancouver,
        British Columbia. With over 5+ years of experience,{" "}
        <strong>I develop websites and enterprise web apps.</strong> I'm
        passionate about design and web technology. I'm always looking for new
        ways to push the boundaries of design and front-end technology.
      </p>
      <p>
        This is a stand-in page only. The website launch is coming soon... In
        the meantime, here is my LinkedIn page:{" "}
        <a href="http://linkedin.com/in/ryanrivera">
          http://linkedin.com/in/ryanrivera
        </a>
      </p>
    </Main>
  );
}

export default Home;
